<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'

export default Vue.extend({
  name: 'Dashboard',
  components: {
    Portal
  },
  data: () => ({
    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: true,
        href: ''
      }
    ]
  })
})
</script>
